@import 'app/styles/variables';

// sass-lint:disable no-misspelled-properties
// sass-lint:disable no-vendor-prefixes
.#{$category-trends-prefix}-container {
  display: flex;

  &-searches {
    display: flex;
    flex-wrap: wrap;
  }

  &-desktop,
  &-tablet {
    width: 100%;

    h2 {
      display: inline-flex;
      font-size: 18px;
      font-weight: 600;
      line-height: 22px;
    }

    .container {
      gap: 16px;
      margin-top: 10px;
      display: grid;
      width: 100%;
      grid-template-columns: repeat(auto-fit, minmax(209px, 209px));
      grid-auto-rows: 1fr;
      max-width: 884px;

      .ui-category-trends-entry-container:hover {
        z-index: $z-index-base;
      }

      @media (width <= 1275px) {
        grid-template-columns: repeat(auto-fit, minmax(284px, 284px));
        gap: 16px;
      }
    }
  }

  .container.carousel {
    justify-content: space-between;
    margin-top: 12px;
  }
}
