// sass-lint:disable no-misspelled-properties
@import './items.desktop';

.ui-search-layout--grid {  
  &__container {

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  &__title {
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(284px, 284px));
    gap: 16px;
    justify-content: start;
    width: 100%;
    grid-auto-rows: 0fr;

    .ui-search-layout__item {
      margin-bottom: 0 !important;
    }
  }

  &__button {
    margin-top: 5px;
  }
}
