@import '~@andes/common/index';
@import 'app/styles/variables';

.#{$category-trends-prefix}-header {
  &-title-container {
    width: 100%;
    background: $andes-blue-700;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    display: flex;
  }

  &-mobile-title-container {
    height: 82px;
  }

  &-desktop-title-container {
    height: 82px;
  }

  &-title {
    color: $andes-white;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 0;
    text-align: center;
    white-space: pre-line;
    width: 100%;

    &-category-name {
      display: block;
    }
  }

  &-mobile-title {
    font-size: 20px;
    line-height: 25px;
  }

  &-desktop-title {
    font-size: 24px;
    line-height: 30px;
  }
}
