@import 'app/styles/variables';
@import '~@seo-frontend-components/trends-carousel/index';

.seo-ui-trends-carousel-loading { 
  height: 156px;
}

// Remove when andes is upgraded to latest versions.
.andes-carousel-snapped__pagination--light
  [data-andes-carousel-snapped-pagination-item][data-andes-carousel-snapped-pagination-item-active='true']
  [data-andes-carousel-snapped-pagination-action] {
  background-color: #3483fa;
}

.andes-carousel-snapped__pagination--light
  [data-andes-carousel-snapped-pagination-item]
  [data-andes-carousel-snapped-pagination-action] {
  background-color: rgb(0 0 0 / 10%);
}

.andes-carousel-snapped__pagination [data-andes-carousel-snapped-pagination-item] button {
  border: 0;
  border-radius: 50%;
  height: 6px;
  margin: 0 2px;
  padding: 0;
  width: 6px;
}
