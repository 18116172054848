.ui-search-layout--grid__item  { 
  --poly-max-width: 284px;  
}

.item-search {
  .ui-search-layout__item {
    max-width: 100% !important;
  }

  .slick-slide {
    min-height: 284px !important;
    max-height: 284px !important;
    height: 100%;
  }

  .ui-search-result-image__element {
    min-height: 284px !important;
    max-height: 284px !important;
    height: 100%;
  }

  .#{$search-prefix}-layout__item {
    &:hover {
      .#{$search-prefix}-item__title {
        max-height: 36px !important;
      }
    }
  }
}

// The Motors category has a different image size than the rest of the categories.
.ui-search-result--mot {
  $motors-card-height: 214px;
  .ui-search-result-image__element {
    min-height: $motors-card-height !important;
    max-height: $motors-card-height !important;
  }
}
